"use client";

import { auth } from "@/app/firebaseConfig";
import { signOut } from "firebase/auth";
import { Check, Menu as MenuIcon, Monitor, Moon, SunDim } from "lucide-react";
import { useTheme } from "next-themes";
import { Button } from "./button";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

const appearances = [
  {
    theme: "System",
    icon: <Monitor className="h-4 w-4" />,
  },
  {
    theme: "Light",
    icon: <SunDim className="h-4 w-4" />,
  },
  {
    theme: "Dark",
    icon: <Moon className="h-4 w-4" />,
  },
];

export default function Menu() {
  const { theme: currentTheme, setTheme } = useTheme();

  const handleViewProfile = () => {
    // Logic to view profile
    console.log("View Profile clicked");
  };

  const handleSignOut = () => {
    // Logic to sign out
    signOut(auth);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="icon">
          <MenuIcon width={16} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-52 p-2" align="end">
        <p className="p-2 text-xs font-medium text-muted-foreground">Appearance</p>
        {appearances.map(({ theme, icon }) => (
          <Button
            variant="ghost"
            key={theme}
            className="flex w-full items-center justify-between rounded px-2 py-1.5 text-sm"
            onClick={() => {
              setTheme(theme.toLowerCase());
            }}
          >
            <div className="flex items-center space-x-2">
              <div className="rounded-sm border  p-1">{icon}</div>
              <span>{theme}</span>
            </div>
            {currentTheme === theme.toLowerCase() && <Check className="h-4 w-4" />}
          </Button>
        ))}
        <div className="mt-2 border-t border-stone-200 pt-2">
          <Button
            variant="ghost"
            className="flex w-full items-center justify-between rounded px-2 py-1.5 text-sm"
            onClick={handleViewProfile}
          >
            <div className="flex items-center space-x-2">
              <span>
                View Profile <span className="text-blue-600">(@shakil99)</span>{" "}
              </span>
            </div>
          </Button>
          <Button
            variant="ghost"
            className="flex w-full items-center justify-between rounded px-2 py-1.5 text-sm"
            onClick={handleSignOut}
          >
            <div className="flex items-center space-x-2">
              <span>Sign Out</span>
            </div>
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
