"use client";
import { db } from "@/app/firebaseConfig";
import { defaultEditorContent } from "@/lib/content";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import {
  EditorCommand,
  EditorCommandEmpty,
  EditorCommandItem,
  EditorCommandList,
  EditorContent,
  type EditorInstance,
  EditorRoot,
  type JSONContent,
} from "novel";
import { ImageResizer, handleCommandNavigation } from "novel/extensions";
import { handleImageDrop, handleImagePaste } from "novel/plugins";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { defaultExtensions } from "./extensions";
import GenerativeMenuSwitch from "./generative/generative-menu-switch";
import { uploadFn } from "./image-upload";
import { ColorSelector } from "./selectors/color-selector";
import { LinkSelector } from "./selectors/link-selector";
import { MathSelector } from "./selectors/math-selector";
import { NodeSelector } from "./selectors/node-selector";
import { TextButtons } from "./selectors/text-buttons";
import { slashCommand, suggestionItems } from "./slash-command";
import { Separator } from "./ui/separator";

const extensions = [...defaultExtensions, slashCommand];

interface TailwindAdvancedEditorProps {
  pageId: string;
  initialTitle: string;
  onTitleChange: (title: string) => void;
}

const TailwindAdvancedEditor = ({
  pageId,
  initialTitle: initialTitleProp,
  onTitleChange,
}: TailwindAdvancedEditorProps) => {
  const [initialContent, setInitialContent] = useState<JSONContent | null>(null);
  const [initialTitle, setInitialTitle] = useState(initialTitleProp);
  const [saveStatus, setSaveStatus] = useState("Saved");
  const [charsCount, setCharsCount] = useState(0);

  const [openNode, setOpenNode] = useState(false);
  const [openColor, setOpenColor] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [openAI, setOpenAI] = useState(false);

  const debouncedUpdates = useDebouncedCallback(async (editor: EditorInstance) => {
    const json = editor.getJSON();
    setCharsCount(editor.storage.characterCount.words());
    updateDoc(doc(db, "pages", pageId), {
      content: { html: editor.getHTML(), novel: JSON.stringify(json), markdown: editor.storage.markdown.getMarkdown() },
    });
    setSaveStatus("Saved");
  }, 1000);

  useEffect(() => {
    const fetchDoc = () => {
      const unsubscribe = onSnapshot(doc(db, "pages", pageId), (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.content?.novel) {
            setInitialContent(JSON.parse(data.content.novel));
            setInitialTitle(data.title);
          } else {
            setInitialContent(defaultEditorContent);
          }
        } else {
          setInitialContent(defaultEditorContent);
        }
      });

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    };

    const unsubscribe = fetchDoc();
    localStorage.setItem("currentPageId", pageId);
    return () => unsubscribe();
  }, [pageId]);

  useEffect(() => {
    setInitialTitle(initialTitleProp);
  }, [initialTitleProp]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInitialTitle(e.target.value);
    onTitleChange(e.target.value);
    setSaveStatus("Unsaved");
  };

  return (
    <div>
      <input
        className="w-full leading-4 p-2 pb-3 mb-10 text-4xl font-bold bg-background border-b border-muted focus:outline-none"
        value={initialTitle}
        onChange={handleTitleChange}
        type="text"
        name="title"
        id="title"
      />
      <div className="relative w-full max-w-screen-2xl">
        <div className="flex absolute right-5 top-5 z-10 mb-5 gap-2">
          <div className="rounded-lg bg-accent px-2 py-1 text-sm text-muted-foreground">{saveStatus}</div>
          <div className={charsCount ? "rounded-lg bg-accent px-2 py-1 text-sm text-muted-foreground" : "hidden"}>
            {charsCount} Words
          </div>
        </div>
        <EditorRoot>
          <EditorContent
            key={JSON.stringify(initialContent)}
            initialContent={initialContent}
            extensions={extensions}
            className="relative min-h-[500px] w-full max-w-screen-2xl border-muted bg-background sm:mb-[calc(20vh)] sm:rounded-lg sm:border sm:shadow-lg"
            editorProps={{
              handleDOMEvents: {
                keydown: (_view, event) => handleCommandNavigation(event),
              },
              handlePaste: (view, event) => handleImagePaste(view, event, uploadFn),
              handleDrop: (view, event, _slice, moved) => handleImageDrop(view, event, moved, uploadFn),
              attributes: {
                class:
                  "prose prose-lg dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full",
              },
            }}
            onUpdate={({ editor }) => {
              debouncedUpdates(editor);
              setSaveStatus("Unsaved");
            }}
            slotAfter={<ImageResizer />}
          >
            <EditorCommand className="z-50 h-auto max-h-[330px] overflow-y-auto rounded-md border border-muted bg-background px-1 py-2 shadow-md transition-all">
              <EditorCommandEmpty className="px-2 text-muted-foreground">No results</EditorCommandEmpty>
              <EditorCommandList>
                {suggestionItems.map((item) => (
                  <EditorCommandItem
                    value={item.title}
                    onCommand={(val) => item.command(val)}
                    className="flex w-full items-center space-x-2 rounded-md px-2 py-1 text-left text-sm hover:bg-accent aria-selected:bg-accent"
                    key={item.title}
                  >
                    <div className="flex h-10 w-10 items-center justify-center rounded-md border border-muted bg-background">
                      {item.icon}
                    </div>
                    <div>
                      <p className="font-medium">{item.title}</p>
                      <p className="text-xs text-muted-foreground">{item.description}</p>
                    </div>
                  </EditorCommandItem>
                ))}
              </EditorCommandList>
            </EditorCommand>

            <GenerativeMenuSwitch open={openAI} onOpenChange={setOpenAI}>
              <Separator orientation="vertical" />
              <NodeSelector open={openNode} onOpenChange={setOpenNode} />
              <Separator orientation="vertical" />

              <LinkSelector open={openLink} onOpenChange={setOpenLink} />
              <Separator orientation="vertical" />
              <MathSelector />
              <Separator orientation="vertical" />
              <TextButtons />
              <Separator orientation="vertical" />
              <ColorSelector open={openColor} onOpenChange={setOpenColor} />
            </GenerativeMenuSwitch>
          </EditorContent>
        </EditorRoot>
      </div>
    </div>
  );
};

export default TailwindAdvancedEditor;
