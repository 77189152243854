"use client";
import TailwindAdvancedEditor from "@/components/tailwind/advanced-editor";
import Menu from "@/components/tailwind/ui/menu";
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { collection, doc, getDoc, getDocs, onSnapshot, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { deleteObject, listAll, ref } from "firebase/storage";
import Image from "next/image";
import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { v4 as uuidv4 } from "uuid";
import { auth, db, storage } from "./firebaseConfig";

interface Page {
  id: string;
  title: string;
  parentId: string | null;
  expanded: boolean;
  locked: boolean;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  content: any; // Adjust this type as per the content format
  children?: Page[]; // Optional property for UI purposes
}

const initialPages: Page[] = [];

export default function PageComponent() {
  const [pages, setPages] = useState<Page[]>(initialPages);
  const [selectedPage, setSelectedPage] = useState<Page | null>(null);
  const [editingPageId, setEditingPageId] = useState<string | null>(null);
  const [editingPageTitle, setEditingPageTitle] = useState<string>("");
  const [showMenuId, setShowMenuId] = useState<string | null>(null);
  const [sidebarWidth, setSidebarWidth] = useState(300); // Initial sidebar width
  const [isResizing, setIsResizing] = useState(false);
  const [initialClientX, setInitialClientX] = useState(0);
  const [initialSidebarWidth, setInitialSidebarWidth] = useState(300);
  const [isLoggedIn, setIsLoggedIn] = useState("");

  const fetchPages = async () => {
    if (isLoggedIn !== "YES") {
      return;
    }
    const unsubscribe = onSnapshot(collection(db, "pages"), (querySnapshot) => {
      const flatPages: Page[] = querySnapshot.docs.map((doc) => doc.data() as Page);
      const nestedPages = buildNestedPages(flatPages);
      setPages(nestedPages);
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  };

  const handleSignInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const res = await signInWithPopup(auth, provider);
    const { user } = res;
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      // create new firestoreUser
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      const firestoreUser: any = {
        id: user.uid,
        name: user.displayName ?? "",
        path: `users/${user.uid}`,
        email: user.email ?? "",
        image: user.photoURL ?? "",
        role: "member",
      };
      await setDoc(doc(db, "users", user.uid), firestoreUser);
    }
    window.location.reload();
  };

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const firestoreUser = { id: user.uid, ...docSnap.data() } as any;
        // verify if the user has the role
        if (firestoreUser.role === "member") {
          await auth.signOut();
        } else {
          if (pages.length === 0) {
            fetchPages();
          }
        }
      }
      if (isLoggedIn !== "YES") {
        setIsLoggedIn("YES");
      }
    } else {
      if (isLoggedIn !== "NO") {
        setIsLoggedIn("NO");
      }
    }
  });

  const buildNestedPages = (flatPages: Page[], parentId: string | null = null): Page[] => {
    return flatPages
      .filter((page) => page.parentId === parentId)
      .map((page) => ({
        ...page,
        children: buildNestedPages(flatPages, page.id),
      }));
  };

  const addPage = (parentId: string | null = null) => {
    const newPage: Page = {
      id: uuidv4(),
      title: "New Page",
      parentId,
      expanded: true,
      locked: false,
      content: { type: "doc", content: [] },
    };
    setDoc(doc(db, "pages", newPage.id), newPage);
    if (parentId) {
      setPages((prevPages) => addPageToParent(prevPages, parentId, newPage));
    } else {
      setPages((prevPages) => [...prevPages, newPage]);
    }
  };

  const addPageToParent = (pages: Page[], parentId: string, newPage: Page): Page[] => {
    return pages.map((page) => {
      if (page.id === parentId) {
        return { ...page, children: [...(page.children || []), newPage] };
      }
      if (page.children && page.children.length > 0) {
        return { ...page, children: addPageToParent(page.children, parentId, newPage) };
      }
      return page;
    });
  };

  const updatePageTitleInState = (pages: Page[], pageId: string, title: string): Page[] => {
    return pages.map((page) => {
      if (page.id === pageId) {
        return { ...page, title };
      }
      if (page.children && page.children.length > 0) {
        return { ...page, children: updatePageTitleInState(page.children, pageId, title) };
      }
      return page;
    });
  };

  const updatePageTitle = (pageId: string, title: string) => {
    updateDoc(doc(db, "pages", pageId), { title });
  };

  const debouncedUpdatePageTitle = useDebouncedCallback((pageId: string, title: string) => {
    updatePageTitle(pageId, title);
  }, 500);

  const handleTitleBlur = () => {
    if (editingPageId) {
      debouncedUpdatePageTitle(editingPageId, editingPageTitle);
      if (selectedPage && selectedPage.id === editingPageId) {
        setSelectedPage({ ...selectedPage, title: editingPageTitle });
      }
      setEditingPageId(null);
      setEditingPageTitle("");
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingPageTitle(e.target.value);
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    setPages((prevPages) => updatePageTitleInState(prevPages, editingPageId!, e.target.value));
    if (editingPageId) {
      debouncedUpdatePageTitle(editingPageId, e.target.value);
    }
  };

  const toggleExpand = (pageId: string) => {
    setPages((prevPages) => toggleExpandState(prevPages, pageId));
  };

  const toggleExpandState = (pages: Page[], pageId: string): Page[] => {
    return pages.map((page) => {
      if (page.id === pageId) {
        return { ...page, expanded: !page.expanded };
      }
      if (page.children && page.children.length > 0) {
        return { ...page, children: toggleExpandState(page.children, pageId) };
      }
      return page;
    });
  };

  const handleDoubleClick = (page: Page) => {
    setEditingPageId(page.id);
    setEditingPageTitle(page.title);
  };

  const handleTitleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleTitleBlur();
    }
  };

  const handleMenuClick = (pageId: string) => {
    setShowMenuId(showMenuId === pageId ? null : pageId);
  };

  const handleDeletePage = (pageId: string) => {
    deletePageById(pageId);
    setPages((prevPages) => deletePageFromState(prevPages, pageId));
    if (selectedPage && selectedPage.id === pageId) {
      setSelectedPage(null);
    }
  };

  const deletePageById = async (pageId: string) => {
    const batch = writeBatch(db);

    const deleteRecursive = async (id: string) => {
      const pageDoc = await getDoc(doc(db, "pages", id));
      if (pageDoc.exists()) {
        const childDocs = await getDocs(collection(db, "pages"));
        const children = childDocs.docs.filter((doc) => doc.data().parentId === id);
        if (children.length > 0) {
          for (const child of children) {
            await deleteRecursive(child.id);
          }
        }
        batch.delete(doc(db, "pages", id));
        const folderPath = `uploads/${id}/`;
        const folderRef = ref(storage, folderPath);
        listAll(folderRef)
          .then((res) => {
            res.items.forEach((itemRef) => {
              // Delete each file
              deleteObject(itemRef)
                .then(() => {
                  console.log(`Deleted ${itemRef.fullPath} successfully`);
                })
                .catch((error) => {
                  console.error(`Error deleting ${itemRef.fullPath}:`, error);
                });
            });

            if (res.prefixes.length > 0) {
              // Handle any subfolders if necessary
              console.log("Subfolders found:", res.prefixes);
            }
          })
          .catch((error) => {
            console.error("Error listing folder contents:", error);
          });
      }
    };
    await deleteRecursive(pageId);
    await batch.commit();
  };

  const deletePageFromState = (pages: Page[], pageId: string): Page[] => {
    return pages
      .filter((page) => page.id !== pageId)
      .map((page) => ({
        ...page,
        children: deletePageFromState(page.children || [], pageId),
      }));
  };

  const handleLockPage = (pageId: string) => {
    setPages((prevPages) => toggleLockState(prevPages, pageId));
  };

  const toggleLockState = (pages: Page[], pageId: string): Page[] => {
    const updatedPages = pages.map((page) => {
      if (page.id === pageId) {
        const updatedPage = { ...page, locked: !page.locked };
        updateDoc(doc(db, "pages", pageId), { locked: updatedPage.locked });
        return updatedPage;
      }
      if (page.children && page.children.length > 0) {
        return { ...page, children: toggleLockState(page.children, pageId) };
      }
      return page;
    });
    return updatedPages;
  };

  const handleTitleUpdate = (title: string) => {
    if (selectedPage) {
      setPages((prevPages) => updatePageTitleInState(prevPages, selectedPage.id, title));
      setSelectedPage({ ...selectedPage, title });
      debouncedUpdatePageTitle(selectedPage.id, title);
    }
  };

  const startResizing = useCallback(
    (event: React.MouseEvent) => {
      setIsResizing(true);
      setInitialClientX(event.clientX);
      setInitialSidebarWidth(sidebarWidth);
    },
    [sidebarWidth],
  );

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resizeSidebar = useCallback(
    (event: MouseEvent) => {
      if (isResizing) {
        const newWidth = initialSidebarWidth + (event.clientX - initialClientX);
        setSidebarWidth(newWidth);
      }
    },
    [isResizing, initialClientX, initialSidebarWidth],
  );

  useEffect(() => {
    document.addEventListener("mousemove", resizeSidebar);
    document.addEventListener("mouseup", stopResizing);

    return () => {
      document.removeEventListener("mousemove", resizeSidebar);
      document.removeEventListener("mouseup", stopResizing);
    };
  }, [resizeSidebar, stopResizing]);

  const renderPages = (pageList: Page[]) => {
    return (
      <ul className="pl-4">
        {pageList.map((page) => (
          <li key={page.id} className="mb-2 relative">
            {" "}
            {/* Ensure the parent is relative */}
            <div className="flex items-center justify-between">
              <div className="flex items-center overflow-hidden">
                {" "}
                {/* Add overflow-hidden */}
                {page.children && page.children.length > 0 && (
                  // biome-ignore lint/a11y/useButtonType: <explanation>
                  <button onClick={() => toggleExpand(page.id)} className="mr-2">
                    {page.expanded ? "-" : "+"}
                  </button>
                )}
                {editingPageId === page.id ? (
                  <input
                    type="text"
                    value={editingPageTitle}
                    onChange={handleTitleChange}
                    onBlur={handleTitleBlur}
                    onKeyDown={handleTitleKeyDown}
                    // biome-ignore lint/a11y/noAutofocus: <explanation>
                    autoFocus
                    className="border-b-2 border-blue-500 outline-none"
                  />
                ) : (
                  // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                  <span
                    onClick={() => setSelectedPage(page)}
                    onDoubleClick={() => handleDoubleClick(page)}
                    className={`cursor-pointer truncate ${selectedPage?.id === page.id ? "text-blue-500" : ""}`}
                    style={{ maxWidth: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {page.title}
                  </span>
                )}
              </div>
              <div className="flex items-center space-x-2 relative">
                {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
                <button onClick={() => addPage(page.id)}>
                  {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-4 h-4 text-blue-500"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                  </svg>
                </button>
                {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
                <button onClick={() => handleMenuClick(page.id)}>
                  {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-4 h-4 mr-4 text-gray-500"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6h.01M12 12h.01M12 18h.01" />
                  </svg>
                </button>
                {showMenuId === page.id && (
                  <div className="absolute right-0 top-6 mt-2 w-32 bg-white border rounded shadow-lg z-10">
                    {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
                    <button
                      onClick={() => handleDeletePage(page.id)}
                      className="block px-4 py-2 text-sm text-red-500 w-full text-left hover:bg-gray-100"
                    >
                      Delete
                    </button>
                    {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
                    <button
                      onClick={() => handleLockPage(page.id)}
                      className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-100"
                    >
                      {page.locked ? "Unlock" : "Lock"}
                    </button>
                    {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
                    <button className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-100">
                      Share
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
                page.expanded ? "max-h-[100000px]" : "max-h-0"
              }`}
            >
              {page.children && page.children.length > 0 && (
                <div className="pl-4 border-l border-gray-300">{renderPages(page.children)}</div>
              )}
            </div>
          </li>
        ))}
      </ul>
    );
  };

  if (isLoggedIn !== "YES") {
    return (
      <div className="flex min-h-screen items-center justify-center">
        {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
        <button onClick={handleSignInWithGoogle} className="bg-blue-500 text-white px-4 py-2 rounded">
          Sign in with Google
        </button>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen flex-col items-center gap-4 py-4 sm:px-5">
      <div className="flex w-full max-w-screen-2xl items-center gap-2 px-4 sm:mb-[calc(5vh)]">
        <Image className="mr-auto" src="https://www.codeflee.com/logo.svg" width={200} height={100} alt="Logo" />
        <Menu />
      </div>

      <div className="flex w-full max-w-screen-2xl">
        <aside className="group relative bg-transparent" style={{ width: sidebarWidth }}>
          {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
          <button onClick={() => addPage(null)} className="mb-4 w-full p-1 bg-blue-500 text-white rounded">
            Add Page
          </button>
          {renderPages(pages)}
          {/* biome-ignore lint/style/useSelfClosingElements: <explanation> */}
          <div
            className="absolute top-0 right-0 h-full w-0 group-hover:w-1 bg-gray-200 cursor-col-resize"
            onMouseDown={startResizing}
          ></div>
        </aside>
        <main className="flex-1 -mt-9 p-4 bg-transparent z-[10]">
          {selectedPage && (
            <TailwindAdvancedEditor
              pageId={selectedPage.id}
              initialTitle={selectedPage.title}
              onTitleChange={handleTitleUpdate}
            />
          )}
        </main>
      </div>
    </div>
  );
}
