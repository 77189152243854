import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyCWugQLoaQcLQXauajqeNfrB2GJsO3ixok",
  authDomain: "novel-f8800.firebaseapp.com",
  projectId: "novel-f8800",
  storageBucket: "novel-f8800.appspot.com",
  messagingSenderId: "842349603280",
  appId: "1:842349603280:web:9960896f9136994808b1d5",
  measurementId: "G-W3JBDTY8JQ",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
export { db, auth, storage };
