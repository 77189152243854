import { storage } from "@/app/firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { createImageUpload } from "novel/plugins";
import { toast } from "sonner";

const onUpload = (file: File) => {
  const pageId = localStorage.getItem("currentPageId");
  const storageRef = ref(storage, `uploads/${pageId}/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    let progress = 0;
    const toastId = toast.loading(`Uploading image... ${progress}% done`);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        toast.loading(`Uploading image... ${progress}% done`, { id: toastId });
      },
      (error) => {
        toast.error(error.message, { id: toastId });
        reject(error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const image = new Image();
          image.src = downloadURL;
          image.onload = () => {
            toast.success("Image uploaded successfully.", { id: toastId });
            resolve(downloadURL);
          };
        } catch (error) {
          toast.error(error.message, { id: toastId });
          reject(error);
        }
      },
    );
  });
};

export const uploadFn = createImageUpload({
  onUpload,
  validateFn: (file) => {
    if (!file.type.includes("image/")) {
      toast.error("File type not supported.");
      return false;
    }
    if (file.size / 1024 / 1024 > 20) {
      toast.error("File size too big (max 20MB).");
      return false;
    }
    return true;
  },
});
